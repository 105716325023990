import React from 'react';
import { graphql } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { Layout, Link, Card } from '@components';
import { Arrow, Signature } from '@styles/icons';
import { Header1, Header2, Header3 } from '@styles';
import { getKeyValue, compileRichText, slugify } from '@utils';
import {
  SMALL, LARGE, MEDIUM, XLARGE, LIGHT, DARK,
} from '@utils/vars';
import {
  Hero, Content, Intro, TableOfContents, TocItem, AllPlansLink, IssuesContent, IssueArticle, Plans,
  PlansContent, PlanItems, IntroWrapper, ArticleLink, IssuesIntroWrapper, IntroContentWrapper,
} from './issues-landing.styles';

const IssuesLandingTemplate = ({ location, data, pageContext }) => {
  const {
    site, page, globalSettings, allIssues, allPolicyProposals,
  } = data;

  const { locale } = pageContext;
  const { title: siteTitle, social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    pageTitle, contentEntry, languages, pageDescription, metaImage, pageUrl,
    disableDonateBar, disableAlertBar, disableSplash, doNotIndex,
  } = page || {};
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};
  const {
    intro, title, readFullIssue, allPlanSectionTitle, jumpToPlansText,
    issuesIntro, allPlanSectionIntro,
  } = contentEntry || {};
  const {
    navigation, enableAlertBar, alertBarLink, alertBarText, footerDisclaimer,
    footerSecondaryLinks, footerPrimaryLinks, footerSocialLinks, footerCopyright, footerAddress,
    enableDonateBar, donationBar, donateBarDisclaimer, enableSplash, splash, footerForm,
    footerEmails, footerPhoneNumber, formLabelLanguageOverrides, footerDonateByMail,
  } = globalSettings || {};

  const footerProps = {
    footerCopyright,
    footerDisclaimer,
    footerSocialLinks,
    footerPrimaryLinks,
    footerSecondaryLinks,
    footerForm,
    footerAddress,
    footerEmails,
    footerPhoneNumber,
    formLabelLanguageOverrides,
    footerDonateByMail,
  };

  const donateBarProps = {
    ...donationBar,
    donateBarDisclaimer,
  };

  const alertBarProps = {
    alertBarLink,
    alertBarText,
  };

  const splashProps = {
    ...splash,
    donateBarDisclaimer,
    formLabelLanguageOverrides,
  };

  const planId = slugify(allPlanSectionTitle);

  return (
    <Layout
      location={location}
      navigation={navigation}
      languages={languages}
      footerProps={footerProps}
      donateBarProps={donateBarProps}
      alertBarProps={alertBarProps}
      enableAlertBar={enableAlertBar && !disableAlertBar}
      enableDonateBar={enableDonateBar && !disableDonateBar}
      enableSplash={enableSplash && !disableSplash}
      metaDescription={pageDescription}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${pageUrl}`}
      metaTitle={`${pageTitle} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      splashProps={splashProps}
      locale={locale}
      fullWidth
      navTheme={DARK}
      mainTheme={LIGHT}
      mobNavTheme={LIGHT}
      doNotIndex={doNotIndex}
    >
      <Hero>
        <Content>
          <Header1 size={SMALL} textTheme="white">
            {title}
          </Header1>
        </Content>
      </Hero>
      <IntroWrapper>
        <Intro>
          {intro && (
            <IntroContentWrapper style={{ position: 'relative' }}>
              {compileRichText({ textTheme: 'primary', size: MEDIUM, locale })(intro)}
              <Signature />
            </IntroContentWrapper>
          )}
          {allIssues && allIssues.edges && (
          <TableOfContents>
            {allIssues.edges.map((issue) => {
              const {
                title: issueTitle,
              } = getKeyValue(issue, 'node') || {};
              const id = slugify(issueTitle);
              if (issueTitle) {
                return (
                  <TocItem>
                    <AnchorLink to={`#${id}`}>
                      <span>{issueTitle}</span>
                      <Arrow strokeColor="primaryHighlight" height="18px" width="20px" />
                    </AnchorLink>
                  </TocItem>
                );
              }
              return null;
            })}
          </TableOfContents>
          )}
          {jumpToPlansText && (
          <AllPlansLink>
            <AnchorLink to={`#${planId}`}>
              {jumpToPlansText}
              <Arrow strokeColor="primary" height="18px" width="20px" />
            </AnchorLink>
          </AllPlansLink>
          )}
        </Intro>
      </IntroWrapper>
      {allIssues && allIssues.edges && (
      <IssuesContent>
        {issuesIntro && (
          <IssuesIntroWrapper>
            {compileRichText({ textTheme: 'primary', size: SMALL, locale })(issuesIntro)}
          </IssuesIntroWrapper>
        )}
        {allIssues.edges.map((issue) => {
          const {
            title: issueTitle, summary, page: issuePage, issueAreaReadMoreText,
          } = getKeyValue(issue, 'node') || {};
          const { pageUrl: issueUrl } = (issuePage && issuePage[0]) || {};
          const id = slugify(issueTitle);
          if (issueTitle) {
            return (
              <IssueArticle>
                <Header3 textTheme="primary" size={XLARGE} id={id}>
                  {issueTitle}
                </Header3>
                {summary && (
                  compileRichText({ textTheme: 'primary', size: SMALL, locale })(summary)
                )}
                <ArticleLink>
                  <Link textTheme="primaryHighlight" href={issueUrl}>
                    <span>{issueAreaReadMoreText || readFullIssue}</span>
                    <Arrow strokeColor="primaryHighlight" height="26px" width="29px" />
                  </Link>
                </ArticleLink>
              </IssueArticle>
            );
          }
          return null;
        })}
      </IssuesContent>
      )}
      {allPolicyProposals && allPolicyProposals.edges && (
      <Plans>
        <PlansContent>
          {allPlanSectionTitle && (
          <Header2 textTheme="white" id={planId}>
            {allPlanSectionTitle}
          </Header2>
          )}
          {allPlanSectionIntro && (
            compileRichText({ textTheme: 'white', locale })(allPlanSectionIntro)
          )}
          <PlanItems>
            {allPolicyProposals.edges.map((policy) => {
              const { title: policyTitle, page: policyPage } = getKeyValue(policy, 'node') || {};
              const { pageUrl: policyUrl } = (policyPage && policyPage[0]) || {};
              if (policyTitle) {
                return (
                  <li>
                    <Card
                      href={policyUrl}
                      size={LARGE}
                      text={policyTitle}
                    />
                  </li>
                );
              }
              return null;
            })}
          </PlanItems>
        </PlansContent>
      </Plans>
      )}
    </Layout>
  );
};

export default IssuesLandingTemplate;

export const issuesLandingPageQuery = graphql`
  query IssuesLandingBySlug($slug: String!, $locale: String!) {
    site {
      ...SiteMetadata
    }
    page: contentfulPage(pageUrl: { eq: $slug }) {
      ...PageMetadata
      contentEntry {
        ... on ContentfulPolicyLanding {
          title
          intro {
            raw
          }
          readFullIssue
          policyLandingOnePager {
            file {
              url
            }
          }
          issuesIntro {
            raw
          }
          policyLandingOnePageDownloadText
          allPlanSectionTitle
          jumpToPlansText
          allPlanSectionIntro {
            raw
          }
        }
      }
    }
    globalSettings: contentfulGlobalSettings(language: { eq: $locale }) {
      ...GlobalSettings
    }
    allIssues: allContentfulKeyIssueArea(sort: {fields: issuesLandingOrder}, filter: {page: {elemMatch: {language: {eq: $locale}, dummyContent: {ne: true}}}, dummyContent: {ne: true}}) {
      edges {
        node {
          id
          title
          summary {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                file {
                  url
                }
                title
              }
            }
          }
          issueAreaReadMoreText
          issuesLandingOrder
          page {
            pageUrl
          }
        }
      }
    }
    allPolicyProposals: allContentfulPlan(filter: {page: {elemMatch: {language: {eq: $locale}, dummyContent: {ne: true}}}, dummyContent: {ne: true}}) {
      edges {
        node {
          id
          title
          page {
            pageUrl
          }
        }
      }
    }
  }
`;
