import styled, { css } from 'styled-components';
import { createBreakpoint } from '@styles';

export const Hero = styled.div`
  display: block;
  background-color: ${({ theme }) => theme.palette.primary};
  padding: 8px ${({ theme }) => theme.layout.gutter}px 380px;

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    padding: 120px ${theme.layout.gutter}px 250px;
  `)}
`;

export const Content = styled.div`
  max-width: ${({ theme }) => theme.layout.contentMaxWidth}px;
  margin: 0px auto;
`;

export const IntroWrapper = styled.div`
  padding: 0px 15px;
`;

export const Intro = styled.div`
  background-color: ${({ theme }) => theme.palette.lighterPrimary};
  max-width: ${({ theme }) => theme.layout.wideMaxWidth}px;
  padding: 30px 17px;
  margin: -350px auto 0px;

  h2 {
    letter-spacing: 0.16em;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 15px;
  }

  p {
    font-size: 18px;
    line-height: 1.9;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    padding: 30px 32px;

    p {
      font-size: 24px;
      line-height: 1.7;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    margin: -150px auto 0px;
    padding: 88px 120px;

    p {
      font-size: 26px;
      line-height: 1.7;
    }
  `)}
`;

export const TableOfContents = styled.ul`
  border-top: 2px solid ${({ theme }) => theme.palette.primaryHighlight};
  border-bottom: 2px solid ${({ theme }) => theme.palette.primaryHighlight};
  margin-top: 32px;
  padding: 15px 0px;
  column-count: 1;
  column-gap: 120px;
  display: block;
  column-width: auto;
  width: 100%;
  position: relative;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-top: 60px;
    column-count: 2;
    column-gap: 50px;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    margin-top: 64px;
    column-gap: 110px;
  `)}
`;

export const TocItem = styled.li`
  color: ${({ theme }) => theme.palette.primaryHighlight};
  display: inline-block;
  padding-right: 10px;
  width: 100%;

  a {
    font-weight: 700;
    font-size: 18px;
    padding: 16px 0px;
    display: flex;
    line-height: 1.2;
    transition: color 0.3s;

    span {
      width: 100%;
    }

    svg {
      margin-left: 10px;
      min-width: 16px;
      width: 16px;
      height: 14.4px;
      transition: transform 0.3s;
      bottom: -4px;
      position: relative;

      polygon {
        transition: fill 0.3s;
      }
    }

    &:hover {
      color: ${({ theme }) => theme.palette.primary};

      svg {
        transform: translateX(40%);
        polygon {
          fill: ${({ theme }) => theme.palette.primary};
        }
      }
    }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    font-size: 20px;
    svg {
      margin-left: 10px;
      min-width: 25px;
      width: 20px;
      height: 18px;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    font-size: 22px;
  `)}

  }
`;

export const AllPlansLink = styled.div`
  color: ${({ theme }) => theme.palette.primary};
  display: flex;
  justify-content: flex-end;
  transition: color 0.3s;

  a {
    font-weight: 700;
    font-size: 18px;
    padding: 10px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    svg {
      margin-left: 10px;
      transform: rotate(90deg);
      transition: transform 0.3s;
      polygon {
        transition: fill 0.3s;
      }
    }

    &:hover {
      color: ${({ theme }) => theme.palette.secondary};
      svg {
        transform: rotate(90deg) translateX(25%);
        polygon {
          fill: ${({ theme }) => theme.palette.secondary};
        }
      }
    }
  }
`;

export const IssuesContent = styled.div`
  max-width: ${({ theme }) => theme.layout.contentMaxWidth}px;
  margin: 0px auto;
`;

export const IssueArticle = styled.article`
  margin: 70px auto;
  padding: 0px ${({ theme }) => theme.layout.gutter}px;

  p {
    font-size: 18px;
    line-height: 1.9;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    p {
      font-size: 22px;
      line-height: 1.9;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    margin: 100px auto;
  `)}
`;

export const ArticleLink = styled.p`
  display: block;
  margin-top: 20px;

  a {
    color: ${({ theme }) => theme.palette.primaryHighlight};
    font-size: 18px;
    font-weight: 700;
    transition: color 0.3s;
    line-height: 1.55;

    span, svg {
      display: inline;
    }

    span {
      margin-right: 34px;
    }

    svg {
      margin-left: -24px;
      min-width: 20px;
      width: 20px;
      height: 18px;
      position: relative;
      top: 4px;
      transition: transform 0.3s;

      polygon {
        transition: fill 0.3s;
      }
    }

    &:hover {
      color: ${({ theme }) => theme.palette.secondary};

      svg {
        transform: translateX(40%);
        polygon {
          fill: ${({ theme }) => theme.palette.secondary};
        }
      }
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-top: 30px;
    a {
      font-size: 22px;
      svg {
        top: 2px;
      }
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    margin-top: 34px;

    a {
      font-size: 22px;
      svg {
        margin-left: -30px;
        min-width: 29px;
        top: unset;
        bottom: -3px;
      }
    }
  `)}
`;

export const Plans = styled.section`
  background-color: ${({ theme }) => theme.palette.secondary};
  padding: 40px 32px;

  h2 {
    letter-spacing: 0.16em;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 15px;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    padding: 60px 32px 20px;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    padding: 80px 32px 20px;
  `)}
`;

export const PlansContent = styled.div`
  max-width: ${({ theme }) => theme.layout.wideMaxWidth}px;
  margin: 0px auto;
`;

export const PlanItems = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  justify-content: flex-start;

  li {
    display: flex;
    min-width: 100%;
    max-width: 100%;
    flex: 1 1 100%;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0px;
    }

    a {
      font-size: 30px;
      line-height: 1.2;
      padding: 30px;
      svg {
        width: 23px;
        height: 21px;
      }
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`

    li {
      display: flex;
      min-width: calc(50% - 20px);
      max-width: calc(50% - 20px);
      margin-right: 40px;
      margin-bottom: 40px;

      &:nth-child(2n) {
        margin-right: 0px;
      }

      &:last-child {
        margin-bottom: 40px;
      }

      a svg {
        width: 23px;
        height: 21px;
      }
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`

    li {
      display: flex;
      min-width: calc(33% - 40px);
      max-width: calc(33% - 40px);
      margin-right: 60px;
      margin-bottom: 60px;

      &:nth-child(2n) {
        margin-right: 60px;
      }

      &:last-child {
        margin-bottom: 60px;
      }

      &:nth-child(3n) {
        margin-right: 0px;
      }

      a {
        padding: 40px 50px;
      }
    }
  `)}
`;

export const IssuesIntroWrapper = styled.div`
  margin: 70px auto;
  padding: 0px ${({ theme }) => theme.layout.gutter}px;

  p {
    font-size: 22px;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    margin: 80px auto;
  `)}
`;

export const IntroContentWrapper = styled.div`
  svg {
    width: 130.68px;
    height: 39.27px;
    position: relative;
    top: 20px;
    margin-bottom: 15px;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    svg {
      width: 198px;
      height: 59.5px;
      top: 30px;
    }
  `)}
`;
